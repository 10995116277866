import React from "react";
import useForm from "./useForm";
import { Button, ButtonToolbar, ButtonGroup, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const AppointmentForm = ({ submitForm }) => {
  const { handleChangeText, handleFormSubmit, setDropdownGender, setDropdownIdType, setDropdownVaccine, setDropdownSecondDose, errors, values, dropdownGender, dropdownIdType, dropdownVaccine, dropdownSecondDose } = useForm(submitForm);

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="contains">
      <br />
      <br />
      <form className="app-wrapper">
        <div>
          <h2 className="title">Create Appointment</h2>
        </div>

        <div className="row">
        <div className="column2">
        <div className="other">
          <label className="label">Vaccination Site</label>
          <input
            disabled={true}
            className="input"
            type="text"
            name="location"
            value={values.location}
            onChange={handleChangeText}
          />
          </div>
        </div>

          <div className="column2">
        <div className="other">
          <label className="label">Appointment Date</label>
          <input
            className="input"
            type="datetime-local"
            name="date"
            value={values.date}
            onChange={handleChangeText}
          />
        </div>
        {errors.date && <p className="error">{errors.date}</p>}
          </div>
        </div>

        <div className="row">
        <div className="column3">
        <div className="name">
          <label className="label">First Name</label>
          <input
            className="input"
            type="text"
            name="firstName"
            value={values.firstName}
            onChange={handleChangeText}
          />
        </div>
        {errors.firstName && <p className="error">{errors.firstName}</p>}
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Last Name</label>
          <input
            className="input"
            type="text"
            name="lastName"
            value={values.lastName}
            onChange={handleChangeText}
          />
        </div>
        {errors.lastName && <p className="error">{errors.lastName}</p>}
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Other</label>
          <input
            className="input"
            type="text"
            name="other"
            value={values.other}
            onChange={handleChangeText}
          />
        </div>
        </div>
        </div>

        <div className="row">
        <div className="column3">
        <div className="name">
          <label className="label">Date of Birth</label>
          <input
            className="input"
            type="date"
            name="dob"
            value={values.dob}
            onChange={handleChangeText}
          />
        </div>
        {errors.dob && <p className="error">{errors.dob}</p>}
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Gender</label>
          <select
            className="input"
            value={dropdownGender}
            onChange={(e) => {setDropdownGender(e.target.value)}}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Neither">Neither</option>
          </select>
        </div>
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Phone Number</label>
          <input
            className="input"
            type="text"
            name="contact"
            value={values.contact}
            onChange={handleChangeText}
          />
        </div>
        {errors.contact && <p className="error">{errors.contact}</p>}
        </div>
        </div>

        <div className="row">
        <div className="column3">
        <div className="name">
          <label className="label">Nationality</label>
          <input
            className="input"
            type="text"
            name="nationality"
            value={values.nationality}
            onChange={handleChangeText}
          />
        </div>
        {errors.nationality && <p className="error">{errors.nationality}</p>}
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Identification Type</label>
          <select
            className="input"
            value={dropdownIdType}
            onChange={(e) => {setDropdownIdType(e.target.value)}}>
            <option value="Identification Card">Identification Card</option>
            <option value="Drivers Permit">Drivers Permit</option>
            <option value="Birth Certificate">Birth Certificate</option>
            <option value="Passport">Passport</option>
          </select>
        </div>
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Identification Number</label>
          <input
            className="input"
            type="text"
            name="idNumber"
            value={values.idNumber}
            onChange={handleChangeText}
          />
        </div>
        {errors.idNumber && <p className="error">{errors.idNumber}</p>}
        </div>
        </div>

        <div className="row">
        <div className="column3">
        <div className="name">
          <label className="label">Address Line 1</label>
          <input
            className="input"
            type="text"
            name="address1"
            value={values.address1}
            onChange={handleChangeText}
          />
        </div>
        {errors.address1 && <p className="error">{errors.address1}</p>}
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Address Line 2</label>
          <input
            className="input"
            type="text"
            name="address2"
            value={values.address2}
            onChange={handleChangeText}
          />
        </div>
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Address Line 3</label>
          <input
            className="input"
            type="text"
            name="address3"
            value={values.address3}
            onChange={handleChangeText}
          />
        </div>
        </div>
        </div>

        <div className="row">
        <div className="column3">
        <div className="name">
          <label className="label">Name of Next of Kin</label>
          <input
            className="input"
            type="text"
            name="nameKin"
            value={values.nameKin}
            onChange={handleChangeText}
          />
        </div>
        {errors.nameKin && <p className="error">{errors.nameKin}</p>}
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Number of Next of Kin</label>
          <input
            className="input"
            type="text"
            name="contactKin"
            value={values.contactKin}
            onChange={handleChangeText}
          />
        </div>
        {errors.contactKin && <p className="error">{errors.contactKin}</p>}
        </div>

        <div className="column3">
        <div className="name">
          <label className="label">Place of Work</label>
          <input
            className="input"
            type="text"
            name="work"
            value={values.work}
            onChange={handleChangeText}
          />
        </div>
        </div>
        </div>

        <div className="row">
        <div className="column2">
        <div className="other">
          <label className="label">Type of Vaccine</label>
          <select
            className="input"
            value={dropdownVaccine}
            onChange={(e) => {setDropdownVaccine(e.target.value)}}>
            <option value="Pfizer">Pfizer</option>
            <option value="Sinopharm">Sinopharm</option>
            <option value="Astrazeneca">Astrazeneca</option>
            <option value="Johnson">Johnson and Johnson</option>
          </select>
        </div>
          </div>

          <div className="column2">
        <div className="other">
          <label className="label">Second Dose?</label>
          <select
            className="input"
            value={dropdownSecondDose}
            onChange={(e) => {setDropdownSecondDose(e.target.value)}}>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
          </div>
        </div>

        <label htmlFor="agree">I Agree that all the information above is correct by selecting the Submit Form Button</label>

        <ButtonToolbar aria-label="Toolbar with button groups">
        <ButtonGroup className="me-2" aria-label="First group">
            <Button type="submit" onClick={handleFormSubmit}>Submit form</Button>
        </ButtonGroup>
        <ButtonGroup className="me-2" aria-label="First group">
            <Button variant="danger" onClick={handleShow}>Book Elsewhere</Button>
        </ButtonGroup>
      </ButtonToolbar>
      
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Book Elsewhere</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to cancel the form?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
            <Link to="/finding-vaccinations-sites">
            <Button variant="primary">Yes</Button>
            </Link>
        </Modal.Footer>
      </Modal>

      </form>
      <br />
      <br />
    </div>
  );
};

export default AppointmentForm;