import React from 'react'
import { Carousel } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

function ControlledCarousel() {
    const [index, setIndex] = React.useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
  
    return (
      <Carousel controls={false} activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/Welcome!.png"
            alt="First slide"
          />
          
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/Types of vaccines (1).png"
            alt="Second slide"
          />
  
          
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/How to get your jab (2).png"
            alt="Third slide"
          />
  
        </Carousel.Item>
      </Carousel>
    );
  }
  
  export default ControlledCarousel;