import { useState, useEffect, useContext } from "react";
import Validation from "./Validation";
import authContext from "../../context/auth-context";

function useFrom(submitForm) {
  const context = useContext(authContext);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [dataIsCorrect, setDataIsCorrect] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrors(Validation(values));
    setDataIsCorrect(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
    const requestBody = {
      query: `
        query {
          login(email: "${values.email}", password: "${values.password}") {
            userId
            token
            tokenExpiration
            isAdmin
          }
        }
      `
    };

    fetch('https://node02.eastus.cloudapp.azure.com/graphql', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      if (res.status !== 200 & res.status !== 201) {
        throw new Error('Failed!')
      }
      return res.json();
    })
    .then(resData => {
      if (resData.data.login.token) {
        context.login(resData.data.login.token, resData.data.login.userId, resData.data.login.tokenExpiration, resData.data.login.isAdmin)
      }
    })
    .catch(err => {
      console.log(err);
    })

      submitForm(true);
    }
  }, [context, dataIsCorrect, errors, submitForm, values.email, values.password]);
  return { handleChange, handleFormSubmit, errors, values };
};

export default useFrom;