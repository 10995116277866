import { useState, useEffect, useContext } from "react";
import { Accordion, Button, Table } from "react-bootstrap";
import axios from "axios";
import authContext from "../../context/auth-context";

import "bootstrap/dist/css/bootstrap.min.css";
import './BookedAppointments.css';

function BookedAppointments (props) {
  const context = useContext(authContext);
  const [appointmentInfo, setAppointmentInfo] = useState({
    loading: false,
    data: null,
  });
  const { data } = appointmentInfo; 

    useEffect(() => {
      setAppointmentInfo({ loading: true })
      axios({
        url: 'https://node02.eastus.cloudapp.azure.com/graphql',
        method: 'post',
        headers: { Authorization: `Bearer ${context.token}` },
        data: {
            query: `
            query {
              appointments(userId: "${context.userId}") {
                _id
                location
                date
                lastName
                firstName
                other
                gender
                contact
                dob
                nationality
                idType
                idNumber
                address1
                address2
                address3
                nameKin
                contactKin
                work
                vaccine
                secondDose
              }
            }
            `
        }
    })
        .then((resData) => {
          return setAppointmentInfo({
            loading: false,
            data: resData.data.data,
          })
        })
        .catch((error) => {
            console.log(error);
          })
    }, [context.token, context.userId])

    function deleteAppointmentHandler(appointmentId) {
      axios({
        url: 'https://node02.eastus.cloudapp.azure.com/graphql',
        method: 'post',
        headers: { Authorization: `Bearer ${context.token}` },
        data: {
            query: `
            mutation {
              cancelAppointment(appointmentId: "${appointmentId}", userId: "${context.userId}"){
                _id
              }
            }
            `
        }
    })
    .catch((error) => {
        console.log(error);
      })
    }

    function date(int) {
      return new Date(Number(int) + (4*60*60*1000)).toLocaleString();
    }

    function dob(int) {
      return new Date(Number(int)).toLocaleDateString();
    }

    const renderAccordion = (app, index) => {
      return (
        <Accordion defaultActiveKey="0" key={index}>
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>Appointment for {app.firstName} {app.other} {app.lastName}</Accordion.Header>
            <Accordion.Body>
            <Table striped borderless	hover variant="light">
      <thead>
        <tr>
          <th>#</th>
          <th>Details</th>
          <th>Information</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Vaccination Site</td>
          <td>{app.location}</td> 
        </tr>
        <tr>
          <td>2</td>
          <td>Date & Time</td>
          <td>{date(app.date)}</td> 
        </tr>
        <tr>
          <td>3</td>
          <td>Vaccine</td>
          <td>{app.vaccine}</td> 
        </tr>
        <tr>
          <td>4</td>
          <td>Second Dose</td>
          <td>{app.secondDose}</td> 
        </tr>
        <tr>
          <td>5</td>
          <td>Gender</td>
          <td>{app.gender}</td> 
        </tr>
        <tr>
          <td>6</td>
          <td>Contact #</td>
          <td>{app.contact}</td> 
        </tr>
        <tr>
          <td>7</td>
          <td>Date of Birth</td>
          <td>{dob(app.dob)}</td> 
        </tr>
        <tr>
          <td>8</td>
          <td>Nationality</td>
          <td>{app.nationality}</td> 
        </tr>
        <tr>
          <td>9</td>
          <td>Identification Type</td>
          <td>{app.idType}</td> 
        </tr>
        <tr>
          <td>10</td>
          <td>Identification Number</td>
          <td>{app.idNumber}</td> 
        </tr>
        <tr>
          <td>11</td>
          <td>Address Line 1</td>
          <td>{app.address1}</td> 
        </tr>
        <tr>
          <td>12</td>
          <td>Address Line 2</td>
          <td>{app.address2}</td> 
        </tr>
        <tr>
          <td>13</td>
          <td>Address Line 3</td>
          <td>{app.address3}</td> 
        </tr>
        <tr>
          <td>14</td>
          <td>Work</td>
          <td>{app.work}</td> 
        </tr>
        <tr>
          <td>15</td>
          <td>Name of Next of Kin</td>
          <td>{app.nameKin}</td> 
        </tr>
        <tr>
          <td>16</td>
          <td>Contact of Next of Kin</td>
          <td>{app.contactKin}</td> 
        </tr>
      </tbody>
    </Table>
    <Button variant="danger" onClick={() => deleteAppointmentHandler(app._id)}>Cancel Appointment</Button>
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
      );
    };

  return (
      <div>{data ? (data.appointments.map(renderAccordion)): null}</div>
  )
}

export default BookedAppointments;