import React from "react";
import SignInForm from "./SignInForm";
import SignInFormSuccess from "./SignInFormSuccess";
import { useState } from "react";
import "./SignIn.css";

const Forms = () => {
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const submitForm = () => {
    setFormIsSubmitted(true);
  };
  return (
    <div>
      {!formIsSubmitted ? (<SignInForm submitForm={submitForm} />) : (<SignInFormSuccess />)}
    </div>
  );
};

export default Forms;
