import { useState, useEffect } from "react";
import Validation from "./Validation";

function useFrom(submitForm) {
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
  });

  const [errors, setErrors] = useState({});
  const [dataIsCorrect, setDataIsCorrect] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrors(Validation(values));
    setDataIsCorrect(true);
  };
  
  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      const requestBody = {
        query: `
          mutation {
            createUser(userInput: {
              firstName: "${values.firstname}",
              lastName: "${values.lastname}",
              email: "${values.email}",
              password: "${values.password}"
            }){
              _id
              email
            }
          }
        `
      };
  
      fetch('https://node02.eastus.cloudapp.azure.com/graphql', {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        if (res.status !== 200 & res.status !== 201) {
          throw new Error('Failed!')
        }
        return res.json();
      })
      .catch(err => {
        console.log(err);
      })
      
      submitForm(true);
    }
  }, [dataIsCorrect, errors, submitForm, values.email, values.firstname, values.lastname, values.password]);
  return { handleChange, handleFormSubmit, errors, values };
};
export default useFrom;