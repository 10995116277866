import React from "react";
import { Link } from "react-router-dom";

const SignUpFormSuccess = () => {
  return (
    <div className="contains">
      <div className="app-wrapper">
        <h1 className="form-success">Account Created!</h1>
        <Link to="/login">
          <div className="Btn">
          <button className="submit">Click Here to Login
          </button>
          </div>
          </Link>
      </div>
    </div>
  );
};

export default SignUpFormSuccess;
