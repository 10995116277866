import React from "react";

const SignInFormSuccess = () => {
  return (
    <div className="contain">
      <div className="app-wrap">
        <h1 className="form-success">Login Successful!</h1>
      </div>
    </div>
  );
};

export default SignInFormSuccess;
