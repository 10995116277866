const Validation = (values) => {
  let errors = {};
  const numberRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  const currentDate = new Date();
  const setTime = currentDate.setHours(currentDate.getHours() - 4);
  
  if (!values.date) {
    errors.date = " Appointment Date is required!";
  } else if (setTime >= values.date) {
    errors.date = " Pick a future date!"
  }

  if (!values.firstName) {
    errors.firstName = " First Name is required!";
  }

  if (!values.lastName) {
    errors.lastName = " Last Name is required!";
  }

  if (!values.dob) {
    errors.dob = " Date of Birth is required!";
  }

  if (!values.contact) {
    errors.contact = " Phone Number is required!"
  } else if (!numberRegex.test(values.contact)) {
    errors.contact = " Phone Number is invalid!"
  } else if (values.contact === values.contactKin) {
    errors.contact = " A different contact number is required!"
  }

  if (!values.nationality) {
    errors.nationality = " Nationality is required!";
  }

  if (!values.idNumber) {
    errors.idNumber = " Identification Number is required!"
  }

  if (!values.address1) {
    errors.address1 = " Address Line 1 is required!";
  }

  if (!values.nameKin) {
    errors.nameKin = " Name of Next of Kin is required!"
  }

  if (!values.contactKin) {
    errors.contactKin = " Phone Number is required!"
  } else if (!numberRegex.test(values.contactKin)) {
    errors.contactKin = " Phone Number is invalid!"
  } else if (values.contactKin === values.contact) {
    errors.contactKin = " A different contact number is required!"
  }

  return errors;
};

export default Validation;