import { Route, Switch, Redirect } from "react-router-dom";
import React, { Component } from 'react';
import authContext from "./context/auth-context";

import HomePage from "./WebsitePages/HomePage";
import VaccinationLocationPage from "./WebsitePages/VaccinationLocationPage";
import BookAppointmentsPage from "./WebsitePages/BookAppointments";
import MainNavigation from "./components/Layout/MainNavigation";
import AppointmentForm from "./WebsitePages/AppointmentForm";

import Loginpage from "./WebsitePages/Loginpage";
import Registerpage from "./WebsitePages/Registerpage";

class App extends Component {
  state = {
    token: null,
    userId: null,
    isAdmin: null,
  }

  login = (token, userId, tokenExpiration, isAdmin) => {
    this.setState({token: token, userId: userId, isAdmin: isAdmin});
  }

  logout = () => {
    this.setState({ token: null, userId: null, isAdmin: null })
  }

  render() {
  return (
    <div>
      <authContext.Provider value={{token: this.state.token, userId: this.state.userId, isAdmin: this.state.isAdmin, login: this.login, logout: this.logout}}>
        <MainNavigation />
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/finding-vaccinations-sites">
             <VaccinationLocationPage />
            </Route>
            {this.state.token && <Route path="/booked-appointment">
             <BookAppointmentsPage />
            </Route>}
            {!this.state.token && <Route path="/booked-appointment">
            <Redirect from='/booked-appointment' to ='/'/>
            </Route>}
            {this.state.token && <Route path="/appointment-form">
             <AppointmentForm />
            </Route>}
            {!this.state.token && <Route path="/appointment-form">
            <Redirect from='/appointment-form' to ='/'/>
            </Route>}
            <Route path="/login">
             <Loginpage />
             {this.state.token && <Redirect from='/login' to ='/'/>}
            </Route>
            <Route path="/register">
             <Registerpage />
           </Route>
           <Route path="*"><Redirect to='/'/></Route>
          </Switch>
      </authContext.Provider>
    </div>
  );
  }
}

export default App;