import React from "react";

const SignUpFormSuccess = () => {
  return (
    <div className="contains">
      <div className="app-wrapper">
        <h1 className="form-success">Appointment Created!</h1>
      </div>
    </div>
  );
};


export default SignUpFormSuccess;
