import React from "react";
import AppointmentForm from "./AppointmentForm";
import AppointmentFormSuccess from "./AppointmentFormSuccess";
import { useState } from "react";
import "./Appointment.css";

const Form = () => {
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const submitForm = () => {
    setFormIsSubmitted(true);
  };
  return (
    <div>
      {!formIsSubmitted ? (<AppointmentForm submitForm={submitForm} />) : (<AppointmentFormSuccess />)}
    </div>
  );
};

export default Form;
