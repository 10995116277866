const Validation = (values) => {
  let errors = {};

  if (!values.firstname) {
    errors.firstname = " First Name is required!";
  }

  if (!values.lastname) {
    errors.lastname = " Last Name is required!";
  }

  if (!values.email) {
    errors.email = "Email is required!";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email is invalid!";
  }
  if (!values.password) {
    errors.password = "Password is required!";
  } else if (values.password.length < 5) {
    errors.password = "Password must be more than five characters.";
  } else if (!values.confirmpassword) {
    errors.confirmpassword = "Please Confrim password!";
  } else if (values.password !== values.confirmpassword) {
    errors.confirmpassword = "Password must match!";
  }

  return errors;
};

export default Validation;
