import { useState, useCallback, useRef, useEffect } from 'react'
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api'
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import authContext from '../../context/auth-context';

import './GMap.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import mapStyles from './GMapStyles';

const libraries = ["places"];
const mapContainerStyle = {
  width: "100vw",
  height: "100vh",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  rotateControl: false,
  minZoom: 9,
  zoom: 9,
  center: { lat: 10.42, lng: -61.22 },
}

const GMap = (props) => {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  let [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedSetLoc, setSelectedSetLoc] = useState(null);

  if (markers.length > 1) {
    markers.shift();
  }

  useEffect(() => {
    if (selectedSetLoc && selectedSetLoc.id === props.id) {
      return;
    }
    getLocations();
  })

  const onMapClick = useCallback((event) => {
    setMarkers(current => [...current, {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      time: new Date(),
    }] 
    )
  }, []);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, [])

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  function getLocations() {
  axios({
    url: 'https://node02.eastus.cloudapp.azure.com/graphql',
    method: 'post',
    data: {
        query: `
        query {
            locations{
              name
              address
              lat
              lng
              image
            }
          }
        `
    }
})
    .then((resData) => {
      setSelectedSetLoc(resData.data.data)
    })
    .catch((error) => {
        console.log(error);
      })
    }

    return (
    <authContext.Consumer>
    {context => {
      return (
        <div>
        <GoogleMap mapContainerStyle={mapContainerStyle}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
        >
        
        {selectedSetLoc ? (selectedSetLoc.locations.map(loc => (<Marker key={loc.name}
        position={{lat: Number(loc.lat), lng: Number(loc.lng)}}
        onClick={() => {
          setSelected(loc);
        }}/>))) : null}
        
        {selected ? (
      <InfoWindow position={{lat: selected.lat, lng: selected.lng}} onCloseClick={() => {setSelected(null)}}>
        <div>
        <img src={selected.image} alt="new" className="googlemaps"/>
        <h2>{selected.name}</h2>
        <h4>{selected.address}</h4>
        <Link to={{pathname:"/appointment-form", state: {
          fromlocation: selected.name
        }}}>
          {context.token && <Button variant="primary" size="sm">Book</Button>}
        </Link>
        <Link to="/login">
          {!context.token && <Button variant="secondary" size="sm">Login to Book</Button>}
        </Link>
        </div>
      </InfoWindow>) : null}
     
          {(context.token && context.isAdmin) && markers.map(marker => (
          <Marker key="Selected Marker"
          position={{lat: marker.lat, lng: marker.lng}}
          onClick={() => {
            setSelected(marker);
          }}
          />
          ))}

          {/* Bug to fix - Admin user popup both items.*/}
          {(context.token && context.isAdmin && selected) ? (
          <InfoWindow position={{lat: selected.lat, lng: selected.lng}} onCloseClick={() => {setSelected(null)}}>
            <div>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/No_image_available_600_x_450.svg/600px-No_image_available_600_x_450.svg.png" alt="new" className="googlemaps"/>
              <h2>I selected this pin</h2>
              <Button variant="primary" size="sm">New Vaccination Site</Button>{' '}
            </div>
          </InfoWindow>) : null}
        </GoogleMap>
      </div>
      )
}}
</authContext.Consumer>
    )
};

export default GMap;