import React from "react";
import SignupForm from "./SignupForm";
import SignUpFormSuccess from "./SignUpFormSuccess";
import { useState } from "react";
import "./SignUp.css";

const Form = () => {
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const submitForm = () => {
    setFormIsSubmitted(true);
  };
  return (
    <div>
      {!formIsSubmitted ? (<SignupForm submitForm={submitForm} />) : (<SignUpFormSuccess />)}
    </div>
  );
};

export default Form;
