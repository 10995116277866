import React from "react";
import { Link } from "react-router-dom";
import useForm from "./useForm";
import { Popover, OverlayTrigger } from 'react-bootstrap';

const SignupForm = ({ submitForm }) => {
  const { handleChange, handleFormSubmit, values, errors } = useForm(submitForm);

  const toc = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Terms and Conditions</Popover.Header>
      <Popover.Body>
        Empty
      </Popover.Body>
    </Popover>
  );
  
  const pp = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Privacy Policy</Popover.Header>
      <Popover.Body>
        Empty
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="contains">
      <br />
      <br />
      <form className="app-wrapper">
        <div>
          <h2 className="title">Create Account</h2>
        </div>
        <div className="name">
          <label className="label">First Name</label>
          <input
            className="input"
            type="text"
            name="firstname"
            value={values.firstname}
            onChange={handleChange}
          />
        </div>
        {errors.firstname && <p className="error">{errors.firstname}</p>}
        <div className="name">
          <label className="label">Last Name</label>
          <input
            className="input"
            type="text"
            name="lastname"
            value={values.lastname}
            onChange={handleChange}
          />
        </div>
        {errors.lastname && <p className="error">{errors.lastname}</p>}
        <div className="email">
          <label className="label">Email</label>
          <input
            className="input"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
        </div>
        {errors.email && <p className="error">{errors.email}</p>}
        <div className="password">
          <label className="label">Password</label>
          <input
            className="input"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
        </div>
        {errors.password && <p className="error">{errors.password}</p>}
        <div className="password">
          <label className="label">Confirm Password</label>
          <input
            className="input"
            type="password"
            name="confirmpassword"
            value={values.confirmpassword}
            onChange={handleChange}
          />
        </div>
        {errors.confirmpassword && <p className="error">{errors.confirmpassword}</p>}
        <div className="chec">
  
          <label htmlFor="agree">
            <p> By clicking the Register Button, you agree to our <br /> 
            <OverlayTrigger trigger="click" placement="top" overlay={toc}><strong> Terms &amp; Conditions</strong></OverlayTrigger> and 
            <OverlayTrigger trigger="click" placement="top" overlay={pp}><strong> Privacy Policy </strong></OverlayTrigger> </p>{" "}
            <Link to="/login">
              <strong>Have an account? Login Instead</strong>
            </Link>
          </label>
        </div>
        <div>
          <button className="submit" onClick={handleFormSubmit}>
            Register
          </button>
        </div>
      </form>
      <br />
      <br />
    </div>
  );
};

export default SignupForm;
