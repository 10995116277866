import Footer from "../components/Layout/Footer";
import BookedAppointments from "../components/BookedAppointments/BookedAppointments";
import React from "react";

function BookAppointmentsPage() {
  return (
    <div>
      <BookedAppointments />
      <Footer />
    </div>
  );
}

export default BookAppointmentsPage;
