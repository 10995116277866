import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import authContext from '../../context/auth-context';
import './MainNavigation.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const MainNavigation = props => (
    <authContext.Consumer>
      {context => {
        return (
          <Navbar bg="custom" variant="dark" expand="md">
          <Container>
          <Navbar.Brand as={Link} to="/">Covid Vaccination Center</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/finding-vaccinations-sites">Vaccination Location</Nav.Link>
            {context.token && <Nav.Link as={Link} to="/booked-appointment">Booked Appointments</Nav.Link>}
            </Nav>
            <Nav>
            <NavDropdown title="Account" id="basic-nav-dropdown">
              {!context.token && <NavDropdown.Item as={Link} to="/login">Login</NavDropdown.Item>}
              {!context.token && <NavDropdown.Item as={Link} to="/register">Register</NavDropdown.Item>}
              {context.token && <NavDropdown.Item as={Link} to="#">Settings</NavDropdown.Item>}
              {context.token && <NavDropdown.Item onClick={context.logout}>Logout</NavDropdown.Item>}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          </Container>
        </Navbar>
        );
      }}
      </authContext.Consumer>
)

export default MainNavigation;