import Footer from "../components/Layout/Footer";
import GMap from "../components/GoogleMaps/GMap";
import React from "react";

function VaccinationLocationPage() {
  return (
    <div>
      <GMap />
      <Footer />
    </div>
  );
}

export default VaccinationLocationPage;
