import React from 'react'
import Forms from '../components/Login/Forms'
import Footer from "../components/Layout/Footer";

function Loginpage() {
    return (
        <div>
           <Forms />
           <Footer />
        </div>
    )
}

export default Loginpage
