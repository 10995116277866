import Footer from "../components/Layout/Footer";
import ControlledCarousel from "../components/Home/ControlledCarousel";
import VaccineData from "../components/Home/VaccineData";
import React from 'react';

function HomePage() {
  return (
    <div>
      <ControlledCarousel />
      <VaccineData />
      <Footer />
    </div>
  );
}

export default HomePage;
