import React from "react";
import { Link } from "react-router-dom";
import useForm from "./useForm";

const SignInForm = ({ submitForm }) => {
  const { handleChange, handleFormSubmit, values, errors } =
    useForm(submitForm);

  return (
    <div className="contain">
      <form className="app-wrap">
        <div>
          <h2 className="title">Sign In</h2>
        </div>
        <div className="email">
          <label className="lab">Email</label>
          <input
            className="input"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
        </div>
        {errors.email && <p className="error">{errors.email}</p>}
        <div className="password">
          <label className="lab">Password</label>
          <input
            className="input"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
        </div>
        {errors.password && <p className="error">{errors.password}</p>}
        <Link to="/register">
        <strong>Don't have an account? Register Here</strong>
        </Link>
        <div className="Btn">
          <button className="submit" onClick={handleFormSubmit}>
            Log In
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;
