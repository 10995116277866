import { useState, useEffect, useContext } from "react";
import Validation from "./Validation";
import { useLocation } from 'react-router-dom';
import authContext from "../../context/auth-context";

function useFrom(submitForm) {
  const loc = useLocation();
  const { fromlocation } = loc.state;
  const context = useContext(authContext);

  const [dropdownGender, setDropdownGender] = useState('Male');
  const [dropdownIdType, setDropdownIdType] = useState('Identification Card');
  const [dropdownVaccine, setDropdownVaccine] = useState('Pfizer');
  const [dropdownSecondDose, setDropdownSecondDose] = useState('No');
  const [values, setValues] = useState({
    location: fromlocation,
    date: "",
    firstName: "",
    lastName: "",
    other: "",
    dob: "",
    contact: "",
    nationality: "",
    idNumber: "",
    address1: "",
    address2: "",
    address3: "",
    nameKin: "",
    contactKin: "",
    work: "",
  });

  const [errors, setErrors] = useState({});
  const [dataIsCorrect, setDataIsCorrect] = useState(false);

  const handleChangeText = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrors(Validation(values));
    setDataIsCorrect(true);
  };
  
  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      const requestBody = {
        query: `
        mutation {
          createAppointment(appointmentInput:{
            date: "${values.date}",
            location: "${values.location}",
            firstName: "${values.firstName}",
            lastName: "${values.lastName}",
            other: "${values.other}",
            dob: "${values.dob}",
            gender: "${dropdownGender}",
            contact: "${values.contact}",
            nationality: "${values.nationality}",
            idType: "${dropdownIdType}",
            idNumber: "${values.idNumber}",
            address1: "${values.address1}",
            address2: "${values.address2}",
            address3: "${values.address3}",
            nameKin: "${values.nameKin}",
            contactKin: "${values.contactKin}",
            work: "${values.work}"
            vaccine: "${dropdownVaccine}",
            secondDose: "${dropdownSecondDose}",
            creator: "${context.userId}"
          } ) {
            _id
          }
        }
        `
      };
  
    fetch('https://node02.eastus.cloudapp.azure.com/graphql', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.token,
      }
    })
      .then(res => {
        if (res.status !== 200 & res.status !== 201) {
          throw new Error('Failed!')
        }
        return res.json();
      })
      .catch(err => {
        console.log(err);
      })

      submitForm(true);
    }
  }, [context.token, context.userId, dataIsCorrect, dropdownGender, dropdownIdType, dropdownSecondDose, dropdownVaccine, errors, submitForm, values.address1, values.address2, values.address3, values.contact, values.contactKin, values.date, values.dob, values.firstName, values.idNumber, values.lastName, values.location, values.nameKin, values.nationality, values.other, values.work]);
  return { handleChangeText, handleFormSubmit, setDropdownGender, setDropdownIdType, setDropdownVaccine, setDropdownSecondDose, errors, values, dropdownGender, dropdownIdType, dropdownVaccine, dropdownSecondDose };
};
export default useFrom;