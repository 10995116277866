import React from 'react'
import useFetch from "react-fetch-hook";
import classes from './VaccineData.module.css'

const VaccineData = () => {
    const { isLoading, error, data } = useFetch("https://disease.sh/v3/covid-19/vaccine/coverage/countries/TT?lastdays=2&fullData=true");

    if (isLoading) return "Loading...";
    if (error) return "Error!";
  
    return (
      <section className={classes.vac}>
          <h2>{data.country} Vaccination Info</h2>
          <div className={classes.row}>
            <div className={classes.column}>
                <h3>Last Updated</h3>
                <h4>{data.timeline[0].date}</h4>
            </div>
            <div className={classes.column}>
                <h3>Daily Vaccines Distributed</h3>
                <h4>{data.timeline[0].daily} Vaccines</h4>
            </div>
            <div className={classes.column}>
                <h3>Total Vaccines Distributed</h3>
                <h4>{data.timeline[0].total} Vaccines</h4>
            </div>
        </div>
      </section>
    );

}
export default VaccineData;