import React from 'react'
import Form from '../components/Appointment/Form'
import Footer from "../components/Layout/Footer";

function Appointmentpage() {
    return (
        <div>
            <Form />
            <Footer />
        </div>
    )
}

export default Appointmentpage
